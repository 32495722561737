<template>
  <div class="grey lighten-4">
    <v-img
      :src="require('../assets/Load_Optimization_UX_Workshop.jpg')"
      height="500px"
      style="overflow: visible; margin-bottom: 250px"
    >
      <v-row>
        <v-spacer></v-spacer>
        <v-col>
          <v-avatar style="margin-top: 50%" height="500px" width="500px">
            <v-img :src="require('../assets/avatar.jpg')"></v-img> </v-avatar
        ></v-col>
        <v-spacer></v-spacer
      ></v-row>
    </v-img>
    <v-container>
      <v-row class="text-center">
        <v-col cols="12" class="my-5">
          <div class="text-h5 text-center">
            A UX focused full-stack engineer with 6+ years experience in agile
            and waterfall .NET development.
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-banner class="text-center">Development</v-banner>
              </v-col>
            </v-row>
            <SkillMeter :skill-name="'C#'" :mastery="90"></SkillMeter>
            <SkillMeter :skill-name="'VB'" :mastery="50"></SkillMeter>
            <SkillMeter :skill-name="'Javascript ES6'" :mastery="75"></SkillMeter>
            <SkillMeter :skill-name="'CSS'" :mastery="70"></SkillMeter>
            <SkillMeter :skill-name="'SQL'" :mastery="40"></SkillMeter>
            <SkillMeter :skill-name="'ASP.NET Core'" :mastery="80"></SkillMeter>
            <SkillMeter :skill-name="'Vue.js'" :mastery="80"></SkillMeter>
            <SkillMeter :skill-name="'Vuetify'" :mastery="75"></SkillMeter>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-banner class="text-center">User Centered Design</v-banner>
              </v-col>
            </v-row>
            <SkillMeter :skill-name="'Requirements Elicitation'" :mastery="90"></SkillMeter>
            <SkillMeter :skill-name="'Task Breakdown and Backlogging'" :mastery="60"></SkillMeter>
            <SkillMeter :skill-name="'Painpoint Analysis'" :mastery="50"></SkillMeter>
            <SkillMeter :skill-name="'Prototyping'" :mastery="75"></SkillMeter>
            <SkillMeter :skill-name="'Indigo Studio'" :mastery="85"></SkillMeter>
            <SkillMeter :skill-name="'InVision'" :mastery="40"></SkillMeter>
            <SkillMeter :skill-name="'Usability Analysis'" :mastery="70"></SkillMeter>
            <SkillMeter :skill-name="'Moderated Testing'" :mastery="80"></SkillMeter>
            <SkillMeter :skill-name="'Unoderated Testing'" :mastery="50"></SkillMeter>
            <SkillMeter :skill-name="'Interaction Design'" :mastery="80"></SkillMeter>
            <SkillMeter :skill-name="'Visual Design'" :mastery="75"></SkillMeter>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SkillMeter from "@/components/SkillMeter.vue";

export default {
  name: "Home",
  components: { SkillMeter },
  data: () => ({}),
};
</script>
<style>
html {
  overflow-y: auto;
}
</style>