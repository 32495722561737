<template>
  <v-row class="pa-3">
    <v-col cols="12" md="3" class="text-right">
      <span>{{ skillName }}</span>
    </v-col>
    <v-col cols="12" md="9">
      <div class="meter">
        <span :style="{ width: mastery + '%' }"></span>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {},
  props: {
    skillName: String,
    mastery: Number,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
  },
};
</script>

<style>
html {
  overflow-y: auto;
}

.meter {
  height: 20px;
  position: relative;
  background: #555;
  border-radius: 5px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}

.meter > span {
  display: block;
  height: 100%;
  border-radius: 5px;
  background-color: rgb(43, 194, 83);
  background-image: linear-gradient(
    center bottom,
    rgb(43, 194, 83) 37%,
    rgb(84, 240, 84) 69%
  );
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
}
</style>