<template>
  <v-app>
    <!-- 
  <v-app-bar app>
  </v-app-bar> -->
    <v-main app>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data: () => ({}),
};
</script>
